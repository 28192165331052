import React from "react";

type PlansListProps = {
  country: "cl" | "mx";
};

const PlansList = (props: PlansListProps) => {
  return <div>Hola pacientes de {props.country}</div>;
};

export default PlansList;
