import { AxiosResponse } from "axios";

import api from "~/utils/api/api";

class SubscriptionsAPI {
  getAllSubscriptions = async (country: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/subscriptions/?status=active&plan__country=${country}`);
  };

  getFilteredSubscriptions = async (filters: any): Promise<AxiosResponse<any>> => {
    let url = `v2/subscriptions/?status=active&plan__country=${filters.country}`;
    if (filters.category !== "" && filters.category !== "Todas") {
      url += `&plan__category=${filters.category}`;
    }
    if (filters.search !== "") {
      url += `&search=${filters.search}`;
    }
    return api.get(url);
  };

  getSubscriptionDetail = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/subscriptions/${id}`);
  };

  deleteSubscriptionItem = async (subscriptionId: string, subscriptionItemId: string): Promise<AxiosResponse<any>> => {
    return api.put(`v2/subscriptions/${subscriptionId}/update_subscription_item/`, {
      subscription_item_id: subscriptionItemId,
      active: false,
    });
  };

  editSubscriptionItem = async (subscriptionId: string, data: object): Promise<AxiosResponse<any>> => {
    return api.put(`v2/subscriptions/${subscriptionId}/update_subscription_item/`, data);
  };

  addSubscriptionItem = async (subscriptionId: string, data: object): Promise<AxiosResponse<any>> => {
    return api.post(`v2/subscriptions/${subscriptionId}/add_subscription_item/`, data);
  };

  editSubscription = async (subscriptionId: string, data: object): Promise<AxiosResponse<any>> => {
    return api.put(`v2/subscriptions/${subscriptionId}/update_subscription/`, data);
  };

  pauseSubscription = async (subscriptionId: string, reason?: string): Promise<AxiosResponse<any>> => {
    return api.put(`v2/subscriptions/${subscriptionId}/pause-subscription/`, { reason });
  };

  addCoupon = async (subscriptionId: string, coupon: object): Promise<AxiosResponse<any>> => {
    return api.post(`v2/subscriptions/${subscriptionId}/add_coupon/`, coupon);
  };

  editCoupon = async (subscriptionId: string, coupon: object): Promise<AxiosResponse<any>> => {
    return api.put(`v2/subscriptions/${subscriptionId}/update_coupon/`, coupon);
  };
}

const subscriptionsAPI = new SubscriptionsAPI();
export default subscriptionsAPI;
