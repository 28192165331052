import React, { useState } from "react";

import clsx from "clsx";

import ActiveSubscriptions from "~/components/Subscriptions/ActiveSubscriptions";
import PlansList from "~/components/Subscriptions/PlansList";

type SubscriptionsSubmenuProps = {
  country: "cl" | "mx";
};

const SubscriptionsSubmenu = (props: SubscriptionsSubmenuProps) => {
  const [selectedOption, setSelectedOption] = useState<"subscriptions" | "plans" | undefined>(undefined);
  return (
    <div>
      {!selectedOption && (
        <div className="flex flex-col">
          <div
            className={clsx(
              "flex items-center pl-2",
              "border bg-slate-100",
              "w-full h-16",
              "text-xl",
              "mt-4",
              "hover:cursor-pointer",
            )}
            onClick={() => setSelectedOption("subscriptions")}
          >
            Suscripciones activas
          </div>
          <div
            className={clsx(
              "flex items-center pl-2",
              "border bg-slate-100",
              "w-full h-16",
              "text-xl",
              "hover:cursor-pointer",
            )}
            onClick={() => setSelectedOption("plans")}
          >
            Planes
          </div>
        </div>
      )}
      {selectedOption && selectedOption === "subscriptions" && <ActiveSubscriptions country={props.country} />}
      {selectedOption && selectedOption === "plans" && <PlansList country={props.country} />}
    </div>
  );
};

const SubscriptionsPage = () => {
  const [country, setCountry] = useState<"cl" | "mx" | undefined>(undefined);
  return (
    <div>
      <h1>Subscripciones</h1>
      {!country && (
        <div className="flex flex-col">
          <div
            className={clsx(
              "flex items-center pl-2",
              "border bg-slate-100",
              "w-full h-16",
              "text-xl",
              "mt-4",
              "hover:cursor-pointer",
            )}
            onClick={() => setCountry("cl")}
          >
            Chile
          </div>
          <div
            className={clsx(
              "flex items-center pl-2",
              "border bg-slate-100",
              "w-full h-16",
              "text-xl",
              "hover:cursor-pointer",
            )}
            onClick={() => setCountry("mx")}
          >
            México
          </div>
        </div>
      )}
      {country && <SubscriptionsSubmenu country={country} />}
    </div>
  );
};

export default SubscriptionsPage;
